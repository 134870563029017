const heroBtn = document.querySelector(".js-hero-btn");

heroBtn.addEventListener("mouseenter", () => {
  heroBtn.innerHTML = `829 426-1492`;
});

heroBtn.addEventListener("mouseleave", () => {
  heroBtn.innerHTML = `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 16 20"
                >
                  <path
                    fill="#fff"
                    d="m15.544 13.957-3.5-1.782a.64.64 0 0 0-.474-.045.748.748 0 0 0-.401.301l-1.55 2.25c-2.433-1.363-4.39-3.688-5.538-6.576l1.894-1.841a.922.922 0 0 0 .254-.477 1.044 1.044 0 0 0-.038-.562l-1.5-4.156a.851.851 0 0 0-.352-.442.65.65 0 0 0-.508-.074l-3.25.89a.748.748 0 0 0-.418.314A1 1 0 0 0 0 2.31c0 9.52 6.497 17.22 14.5 17.22a.68.68 0 0 0 .467-.194.925.925 0 0 0 .264-.497l.75-3.86a1.054 1.054 0 0 0-.063-.605.832.832 0 0 0-.374-.418Z"
                  />
                </svg>

               CALL US!`;
});

heroBtn.addEventListener("focusout", () => {
  heroBtn.innerHTML = `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewbox="0 0 16 20"
                >
                  <path
                    fill="#fff"
                    d="m15.544 13.957-3.5-1.782a.64.64 0 0 0-.474-.045.748.748 0 0 0-.401.301l-1.55 2.25c-2.433-1.363-4.39-3.688-5.538-6.576l1.894-1.841a.922.922 0 0 0 .254-.477 1.044 1.044 0 0 0-.038-.562l-1.5-4.156a.851.851 0 0 0-.352-.442.65.65 0 0 0-.508-.074l-3.25.89a.748.748 0 0 0-.418.314A1 1 0 0 0 0 2.31c0 9.52 6.497 17.22 14.5 17.22a.68.68 0 0 0 .467-.194.925.925 0 0 0 .264-.497l.75-3.86a1.054 1.054 0 0 0-.063-.605.832.832 0 0 0-.374-.418Z"
                  />
                </svg>

                CALL US!`;
});
